// import
import "./FooterStyles.css";

//
const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div>
          <h1>HSR</h1>
          <p> Escoge el servicio que mas te guste. </p>
        </div>

        <div>
          {/* ICONO DE FB*/}
          <a href="/">
            <i className="fa-brands fa-facebook-square"></i>
          </a>

          {/* ICONO DE IG */}
          <a href="/">
            <i className="fa-brands fa-instagram-square"></i>
          </a>

          {/* ICONO DE YT*/}
          <a href="/">
            <i className="fa-brands fa-youtube-square"></i>
          </a>

          {/* ICONO DE WSP */}
          <a href="/">
            <i className="fa-brands fa-whatsapp-square"></i>
          </a>
        </div>
      </div>

      <div className="buttom">
        <div>
          <h4>Proyectos</h4>
          <a href="/">Changelog</a>
          <a href="/">Status</a>
          <a href="/">License</a>
          <a href="/">All Version</a>
        </div>

        <div>
          <h4>Comunity</h4>
          <a href="/">GitHub</a>
          <a href="/">Issues</a>
          <a href="/">Project</a>
          <a href="/">Twitter</a>
        </div>

        <div>
          <h4>Help</h4>
          <a href="/">Support</a>
          <a href="/">Troubleshooting</a>
          <a href="/">Contact us</a>
        </div>

        <div>
          <h4>Others</h4>
          <a href="/">Changelog</a>
          <a href="/">Status</a>
          <a href="/">License</a>
          <a href="/">All Version</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
