//
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";
import Service1 from "../assets/1.jpg";
import Service2 from "../assets/2.jpg";

//
const Destination = () => {
  return (
    <div className="destination">
      <h1>Nuestros Servicios</h1>
      <p>Bienvenido a nuestros servicios</p>

      <DestinationData
        className="first-des"
        heading="Servicio N1"
        text="Lorem Ipsum is simply dummy text of the 
        printing and typesetting industry. Lorem Ipsum 
        has been the industry's standard dummy text ever 
        since the 1500s, when an unknown printer took a 
        galley of type and scrambled it to make a type 
        specimen book. It has survived not only five 
        centuries, but also the leap into electronic 
        typesetting."
        img1={Service1}
        img2={Service2}
      />

      <DestinationData
        className="first-des-reverse"
        heading="Servicio N2"
        text="Lorem Ipsum is simply dummy text of the 
        printing and typesetting industry. Lorem Ipsum 
        has been the industry's standard dummy text ever 
        since the 1500s, when an unknown printer took a 
        galley of type and scrambled it to make a type 
        specimen book. It has survived not only five 
        centuries, but also the leap into electronic 
        typesetting."
        img1={Service2}
        img2={Service1}
      />
    </div>
  );
};

export default Destination;
