//
import Destination from "../components/Destination";
import Trip from "../components/Trip";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

//
function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg="https://images.unsplash.com/photo-1649807479468-40011b31ee09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
        title=" BIENVENIDO A HS RODADOS"
        text="Revisa alguno de nuestros servicios."
        url="/"
        btnClass="show"
        buttonText="Solicitar Servicio"
      />
      <Destination />
      <Trip />
      <Footer />
    </>
  );
}

export default Home;
