//
import TripData from "./TripData";
import "./TripStyles.css";
import Service1 from "../assets/1.jpg";
import Service2 from "../assets/2.jpg";

function Trip() {
  return (
    <div className="trip">
      <h1>Ultimos Servicios</h1>
      <p>Puedes ver nuestros ultimos trabajos realizados.</p>
      <div className="tripcard">
        <TripData
          image={Service1}
          heading="Servicios 1"
          text="It is a long established fact that a reader will be 
            distracted by the readable content of a page when looking at 
            its layout. The point of using Lorem Ipsum is that it has a 
            more-or-less normal distribution of letters, as opposed to 
            using 'Content here, content here'"
        />

        <TripData
          image={Service2}
          heading="Servicios 2"
          text="It is a long established fact that a reader will be 
            distracted by the readable content of a page when looking at 
            its layout. The point of using Lorem Ipsum is that it has a 
            more-or-less normal distribution of letters, as opposed to 
            using 'Content here, content here'"
        />

        <TripData
          image={Service1}
          heading="Servicios 3"
          text="It is a long established fact that a reader will be 
            distracted by the readable content of a page when looking at 
            its layout. The point of using Lorem Ipsum is that it has a 
            more-or-less normal distribution of letters, as opposed to 
            using 'Content here, content here'"
        />
      </div>
    </div>
  );
}

export default Trip;
